<template>
  <div>
    <v-container fluid>
      <h1 class="ma-2 ml-0">Integrações</h1>
      <v-card max-width="380" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div>
            <v-list-item-avatar tile width="150px">
                <v-img contain height="40px" src="@/assets/images/svg/correios.svg" d-flex align-start></v-img>
              </v-list-item-avatar>
            </div>
              <v-list-item-subtitle>Integre sua aplicação aos Correios e otimize suas operações
                logísticas.</v-list-item-subtitle>

          </v-list-item-content>
        </v-list-item>

        <v-card-actions class="mt-4 d-flex flex-row-reverse">
          <v-btn outlined color="info" class="text-capitalize" :block="isMobile" @click="correiosIntegration()">
            Configurar
          </v-btn>
        </v-card-actions>
        <CorreiosForm ref="correiosForm"></CorreiosForm>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import CorreiosForm from "@/components/integrations/form/CorreiosForm";

export default {

  components: {
    CorreiosForm
  },

  data() {
    return {
      modal: false,
    }
  },

  methods: {
    correiosIntegration() {
      this.$refs.correiosForm.open();
    }
  },
}
</script>

<style></style>
