<template>
    <v-dialog v-model="dialog" max-width="800px" origin="center center" persistent :fullscreen="isMobile">
        <v-card>
            <div v-if="loading" class="d-flex justify-center mb-2">
                <v-progress-circular indeterminate></v-progress-circular>
            </div>
            <v-card-title v-show="!loading">
                {{ isUpdating ? "Editar" : "Cadastrar" }} Integração com os correios
            </v-card-title>
            <v-divider></v-divider>
            <v-form v-show="!loading" v-model="formValid" ref="form">
                <v-card-text>
                    <div class="row col-xs mt-2">
                        <div class="col-md-6">
                            <v-text-field v-model="correiosForm.client_id" :rules="[rules.required]" label="Usuário"
                                outlined dense hide-details="auto" />
                        </div>
                        <div class="col-md-6">
                            <v-text-field v-model="correiosForm.additional_token" :rules="[rules.required]"
                                label="Cartão postal" outlined dense hide-details="auto" />
                        </div>
                        <div class="col-md-6">
                            <v-text-field v-model="correiosForm.token" :rules="[rules.required]" label="Token" outlined
                                dense hide-details="auto" />
                        </div>
                    </div>
                </v-card-text>
            </v-form>
            <v-card-actions v-show="!loading">
                <v-spacer></v-spacer>
                <v-btn color="secondary" class="text-capitalize" @click="close">
                    Cancelar
                </v-btn>
                <v-btn class="text-capitalize" color="success" @click="submitForm">
                    <v-icon>{{ icons.mdiContentSave }}</v-icon>
                    Salvar Informações
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import formMixin from "@/util/mixins/formMixin";
import axios from 'axios';
import { mdiContentSave, mdiShopping, mdiClose, mdiAccountCheck } from "@mdi/js";
export default {
    name: "CorreiosForm",

    mixins: [formMixin],

    data() {
        return {
            dialog: false,
            isUpdating: false,
            loading: false,
            formValid: null,
            carrier: {},
            correiosForm: {
                id: null,
                client_id: null,
                additional_token: null,
                token: null,
                type: 'Api',
                platform: 'Correios'
            },
            icons: {
                mdiShopping,
                mdiContentSave,
                mdiClose,
                mdiAccountCheck
            },
        };
    },

    mounted() {
      this.carrier = this.$store.state.auth.user.company.carrier

      if (this.carrier != null) {
            this.correiosForm.id = this.carrier.id
            this.correiosForm.client_id = this.carrier.client_id
            this.correiosForm.token = this.carrier.token
            this.correiosForm.additional_token = this.carrier.additional_token
        }
    },

    methods: {
        open() {
            if (this.correiosForm.client_id != null) {
                this.isUpdating = true
            }

            this.dialog = true
        },

        close() {
            this.dialog = false
        },

        submitForm() {
            if (this.$refs.form.validate()) {
                if (this.isUpdating) {
                    this.$http
                        .$put(`/integration/${this.correiosForm.id}`, this.correiosForm)
                        .then((response) => {
                            if (response.status == 200) {
                                this.dialog = false;
                            }
                        }
                    )
                } else {
                    this.$http.$post("/integration", this.correiosForm)
                        .then((response) => {
                            this.dialog = false;
                        }
                    )
                }
            }
        },
    },
};
</script>

<style></style>